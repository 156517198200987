<template>
  <v-card min-height="400">
    <v-card-title>
      <v-col
        cols="12"
        md="10"
        class="text-capitalize"
      >
        {{ selectedTimePeriod }} Cost Per Station
      </v-col>
      <v-col
        cols="10"
        md="2"
      >
        <v-select
          v-model="selectedTimePeriod"
          :items="timePeriod"
          item-text="text"
          item-value="value"
          outlined
          dense
          hide-details
          @change="handleTimePeriodChange"
        ></v-select>
      </v-col>
    </v-card-title>
    <div
      v-if="isCostLoading"
      class="d-flex justify-center align-center"
      style="height: 100%;"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div id="chart">
      <apexchart
        ref="chart"
        type="bar"
        height="400"
        :options="costBarGraphData"
        :series="costBarGraphData.series"
      ></apexchart>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isCostLoading: {
      type: Boolean,
      default: true,
    },
    costBarGraphData: {
      type: Object,
      default: () => ({
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [],
        },
        series: [{
          name: 'Revenue (Ksh)',
          data: [],
        }],
      }),
    },
  },
  data() {
    return {
      selectedTimePeriod: 'monthly',
      timePeriod: [
        { text: 'Daily', value: 'daily' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Monthly', value: 'monthly' },
        { text: 'Yearly', value: 'yearly' },
      ],
    }
  },
  methods: {
    handleTimePeriodChange(year) {
      this.$emit('time-period-change', year)
    },
  },

}
</script>
