<template>
  <v-row
    class="match-height mt-4"
  >
    <v-col
      cols="12"
      md="3"
    >
      <cost-card
        :current-cost-name="'Today'"
        :current-cost="todayCost.today_cost"
        :current-cost-abbreviated="todayCost.today_cost_abbreviated"
        :previous-cost-name="'Yesterday'"
        :previous-cost="todayCost.yesterday_cost"
        :previous-cost-abbreviated="todayCost.yesterday_cost_abbreviated"
        :is-cost-loading="isTodayCostLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <cost-card
        :current-cost-name="'This Week'"
        :current-cost="thisWeekCost.this_week_cost"
        :current-cost-abbreviated="thisWeekCost.this_week_cost_abbreviated"
        :previous-cost-name="'Last Week'"
        :previous-cost="thisWeekCost.last_week_cost"
        :previous-cost-abbreviated="thisWeekCost.last_week_cost_abbreviated"
        :is-cost-loading="isThisWeekCostLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <cost-card
        :current-cost-name="'This Month'"
        :current-cost="thisMonthCost.this_month_cost"
        :current-cost-abbreviated="thisMonthCost.this_month_cost_abbreviated"
        :previous-cost-name="'Last Month'"
        :previous-cost="thisMonthCost.last_month_cost"
        :previous-cost-abbreviated="thisMonthCost.last_month_cost_abbreviated"
        :is-cost-loading="isThisMonthCostLoading"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <cost-card
        :current-cost-name="'This Year'"
        :current-cost="thisYearCost.this_year_cost"
        :current-cost-abbreviated="thisYearCost.this_year_cost_abbreviated"
        :previous-cost-name="'Last Year'"
        :previous-cost="thisYearCost.last_year_cost"
        :previous-cost-abbreviated="thisYearCost.last_year_cost_abbreviated"
        :is-cost-loading="isThisYearCostLoading"
      />
    </v-col>
    <v-col cols="12">
      <cost-line-graph
        :title="'All Cost'"
        :is-cost-loading="isCostBarGraphLoading"
        :cost-bar-graph-data="costBarGraphData"
        @time-period-change="onSelectedTimePeriodChange"
      />
    </v-col>
    <v-col cols="12">
      <cost-bar-graph
        :title="'Cost Per Station'"
        :is-cost-loading="isPerStationCostBarGraphLoading"
        :cost-bar-graph-data="perStationCostBarGraphData"
        @time-period-change="onPerStationCostYearChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import CostCard from './components/CostCard.vue'
import CostLineGraph from './components/CostLineGraph.vue'
import CostBarGraph from './components/CostBarGraph.vue'
import calculateBarWidth from '@/mixins/calculateBarWidth'

export default {
  components: { CostCard, CostLineGraph, CostBarGraph },
  mixins: [calculateBarWidth],
  props: {
    stationId: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    isTodayCostLoading: true,
    isThisWeekCostLoading: true,
    isThisMonthCostLoading: true,
    isThisYearCostLoading: true,
    isCostBarGraphLoading: true,
    isPerStationCostBarGraphLoading: true,
    todayCost: {
      today_cost: 0,
      yesterday_cost: 0,
    },
    thisWeekCost: {
      this_week_cost: 0,
      last_week_cost: 0,
    },
    thisMonthCost: {
      this_month_cost: 0,
      last_month_cost: 0,
    },
    thisYearCost: {
      this_year_cost: 0,
      last_year_cost: 0,
    },
    costBarGraphData: {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Cost (Ksh)',
        data: [],
      }],
    },
    perStationCostBarGraphData: {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      xaxis: {
        categories: [],
      },
      series: [{
        name: 'Cost (Ksh)',
        data: [],
      }],
    },
    selectedTimePeriod: 'monthly',
    selectedPerStationTimePeriod: 'monthly',
  }),
  mounted() {
    this.getTodayCost()
    this.getThisWeekCost()
    this.getThisMonthCost()
    this.getThisYearCost()
    this.getCostOfBarChart()
    this.getPerStationCostOfBarChart()
  },
  methods: {
    getTodayCost() {
      this.isTodayCostLoading = true
      axios
        .get(`analytics/sms/today-cost?stationId=${this.stationId}`)
        .then(response => {
          this.todayCost = response.data
          this.isTodayCostLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isTodayCostLoading = false
        })
    },
    getThisWeekCost() {
      this.isThisWeekCostLoading = true
      axios
        .get(`analytics/sms/this-week-cost?stationId=${this.stationId}`)
        .then(response => {
          this.thisWeekCost = response.data
          this.isThisWeekCostLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isThisWeekCostLoading = false
        })
    },
    getThisMonthCost() {
      this.isThisMonthCostLoading = true
      axios
        .get(`analytics/sms/this-month-cost?stationId=${this.stationId}`)
        .then(response => {
          this.thisMonthCost = response.data
          this.isThisMonthCostLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isThisMonthCostLoading = false
        })
    },
    getThisYearCost() {
      this.isThisYearCostLoading = true
      axios
        .get(`analytics/sms/this-year-cost?stationId=${this.stationId}`)
        .then(response => {
          this.thisYearCost = response.data
          this.isThisYearCostLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isThisYearCostLoading = false
        })
    },
    getCostOfBarChart() {
      this.isCostBarGraphLoading = true
      axios
        .get(`analytics/sms/time-series-cost?timePeriod=${this.selectedTimePeriod}&stationId=${this.stationId}`)
        .then(response => {
          const newData = response.data.data
          const chartLabels = []
          const chartValues = []

          newData.forEach(element => {
            chartValues.push(element.value)
            chartLabels.push(element.period)
          })
          const ref = this

          this.costBarGraphData = {
            ...this.costBarGraphData,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: [{
                name: 'Cost',
                data: chartValues,
              }],
              yaxis: {
                title: {
                  text: 'Amount (Ksh)',
                },
              },
              tooltip: {
                y: {
                  formatter(val) {
                    return `Ksh ${ref.formatCurrency(val)}`
                  },
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: `${this.calculateBarWidth(chartLabels.length)}%`,
                  endingShape: 'rounded',
                },
              },
            },
          }
          this.isCostBarGraphLoading = false
        })
        .catch(error => {
          this.isCostBarGraphLoading = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
    getPerStationCostOfBarChart() {
      this.isPerStationCostBarGraphLoading = true
      axios
        .get(`analytics/sms/time-series-cost-per-station?timePeriod=${this.selectedPerStationTimePeriod}&stationId=${this.stationId}`)
        .then(response => {
          const newData = response.data.data
          let chartLabels = []
          let chartData = []
          const firstStation = newData[0]

          chartLabels = firstStation.data.map(element => element.period)
          chartData = newData.map(element => {
            const { name, data } = element
            const values = data.map(element => element.value)

            return {
              name,
              data: values,
            }
          })
          const ref = this

          this.perStationCostBarGraphData = {
            ...this.perStationCostBarGraphData,
            ...{
              xaxis: {
                categories: chartLabels,
              },
              series: chartData,
              yaxis: {
                title: {
                  text: 'Amount (Ksh)',
                },
              },
              tooltip: {
                y: {
                  formatter(val) {
                    return `Ksh ${ref.formatCurrency(val)}`
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: `${this.calculateBarWidth(chartLabels.length)}%`,
                  endingShape: 'rounded',
                },
              },
            },
          }
          this.isPerStationCostBarGraphLoading = false
        })
        .catch(error => {
          this.isPerStationCostBarGraphLoading = false
          console.log(error)
          this.$notification.error(error.response.data.message)
        })
    },
    onSelectedTimePeriodChange(timePeriod) {
      this.selectedTimePeriod = timePeriod
      this.getCostOfBarChart()
    },
    onPerStationCostYearChange(timePeriod) {
      this.selectedPerStationTimePeriod = timePeriod
      this.getPerStationCostOfBarChart()
    },
    formatCurrency(value) {
      return this.$options.filters.formatCurrencyWithDecimal(value)
    },
  },
}
</script>
